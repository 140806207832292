<script>


/**
 * Landlord  Setup component
 */


 import {
  required,
} from "vuelidate/lib/validators";

import Choices from "choices.js";

import {getPMApi} from '@/api/pm'


export default {

    emits: ['confirm', 'cancel'],

    props: {
        landlord : {
            type : Object,
            default: ()=> {

            }
        }
    },
    components: {
        
    },

    validations() {
      if (this.landlord.rent_type == 'EFT') {
        return {
          landlord : {
            bank_account : {required}
          }
        }
      } else {
        return {
          landlord : {
            bank_account : {}
          }
        }
      }
       
    },
 

    data() {
      
        return {
            submitted : false,
            is_resident_val : false,
        }
       
    },
    methods: {
        formSubmit() {
            this.$v.$touch()
            let data = {
                id             : this.landlord.id,
                code           : this.landlord.code,
                bank_account   :  this.landlord.bank_account,
                institution    :  this.landlord.institution,
                transfer       :  this.landlord.transfer,
                account_number : this.landlord.account_number,

                landlord_name  : this.landlord.name,
                rent_type      : this.landlord.rent_type,

                email          :    this.landlord.email,
                email1         :    this.landlord.email1,
                email2         :    this.landlord.email2,
                is_resident    : this.is_resident_val == true?0 : 1,
                is_send_email  : this.landlord.is_send_email == true ? 1 : 0,

                is_eft_email   : this.landlord.is_send_email == true ? 1 : 0,
                itn            : this.landlord.itn,

                address        : this.landlord.address,
                city_name      : this.landlord.city_name,
                province_name  : this.landlord.province_name,
                country        : this.landlord.country,
            }
            if (this.$v.$invalid == false) {
                getPMApi().update_landlord_bank_info(data).then(res =>{
                    if (res.errCode == 0) {
                        this.$emit('confirm', this.landlord)
                        this.landlord.is_resident = data.is_resident
                    } else {
                        this.$alertify.error("Update Landlord Bank Info Failed code:" + res.errCode);
                    }
                })
               
            }
            
        },
    },

    created() {
       
    },

    mounted() {
      console.log(this.landlord)
      
        new Choices("#rent_type", {
            removeItemButton: false,
            choices : [
                {label : 'Cheque', value : 'CHEQUE', selected: this.landlord.rent_type == 'CHEQUE'? true : false},
                {label : 'EFT', value : 'EFT', selected: this.landlord.rent_type == 'EFT'? true : false},
            ]
        });

        this.is_resident_val = this.landlord.is_resident == 0 ? true : false
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom02">Landlord Name</label>
                            <input class="form-control" v-model="landlord.name"
                             />
                          
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="validationCustom02">Code</label>
                            <input class="form-control" v-model="landlord.code"
                             />
                          
                        </div>
                    </div>

                    <div class="col-md-3 form-check mt-4 mb-3">
                        <div class="mt-3">
                            <label for="validationCustom01">Is Non Resident</label>
                            <input type="checkbox" class="form-check-control" v-model="is_resident_val" :checked="landlord.is_resident == 0" />
                        </div>
                    </div>


                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="validationCustom02">ITN</label>
                            <input class="form-control" v-model="landlord.itn"
                             />
                          
                        </div>
                    </div>


                </div>
                <!-- end row-->


                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                          <label for="workexperience-companyname-input">Rent Type</label>
                          <select id="rent_type" v-model="landlord.rent_type">
                        
                          </select>
                        </div>
                      </div>
                   
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom01">Bank Account Name</label>
                            <input type="text" class="form-control" v-model="landlord.bank_account" placeholder="Enter Bank  Account Name"  :class="{'is-invalid':$v.landlord.bank_account.$error}"  />
                            <div v-if="$v.landlord.bank_account.$error" class="invalid-feedback">
                              <span v-if="!$v.landlord.bank_account.required">This value is required.</span>
                          </div>

                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row mb-3" >
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-website-input" class="form-label">Financial Institution</label>
                        <input type="text" class="form-control" v-model="landlord.institution" placeholder="Enter Bank  Institution Number"  />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Financial Transit</label>
                        <input type="text" class="form-control" v-model="landlord.transfer" placeholder="Enter Bank Transit Number" 
                           />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Financial Account</label>
                        <input type="text" class="form-control" v-model="landlord.account_number" placeholder="Enter Bank Account" 
                         />
                      </div>
                    </div>
                </div>


                <div class="row mb-3" >
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-website-input" class="form-label">Email1</label>
                        <input type="text" class="form-control" v-model="landlord.email" placeholder="Enter Bank  Institution Number"  />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Email2</label>
                        <input type="text" class="form-control" v-model="landlord.email1" placeholder="Enter Bank Transit Number" 
                           />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Email3</label>
                        <input type="text" class="form-control" v-model="landlord.email2" placeholder="Enter Bank Account" 
                         />
                      </div>
                    </div>
                </div>


                <div class="row mb-3" >
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-website-input" class="form-label">Statment By Email</label>
                        <input type="checkbox" class="form-check-control" v-model="landlord.is_send_email" :checked="landlord.is_send_email == 1" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">EFT By Email</label>
                        <input type="checkbox" class="form-check-control" v-model="landlord.is_send_email" :checked="landlord.is_send_email == 1" />
                      </div>
                    </div>
                    
                </div>

                <div class="row mb-3" >
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-website-input" class="form-label">Address</label>
                        <input  type="text" class="form-control" v-model="landlord.address"  />
                      </div>
                    </div>
                </div>


                <div class="row mb-3" >
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-website-input" class="form-label">City</label>
                        <input  type="text" class="form-control" v-model="landlord.city_name" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Province</label>
                        <input  type="text" class="form-control" v-model="landlord.province_name" />
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Country Code</label>
                        <input  type="text" class="form-control" v-model="landlord.country"  />
                      </div>
                    </div>
                    
                </div>


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>