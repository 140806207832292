<script>


/**
 * Landlord GST Setup component
 */


//  import {
//   required,
// } from "vuelidate/lib/validators";




export default {

    emits: ['confirm', 'cancel'],

    props: {
        landlord : {
            type : Object,
            default: ()=> {

            }
        }
    },
    components: {
        
    },

    validations: {
       
    },
 

    data() {
      
        return {
            submitted : false,
        }
       
    },
    methods: {
        formSubmit() {
           
        },
    },

    created() {
       
    },

    mounted() {
    
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom02">Landlord Name</label>
                            <input class="form-control" v-model="landlord.name"
                            
                             />
                          
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom01">Bank Account Name</label>
                            <input type="text" class="form-control" v-model="landlord.bank_account" placeholder="Enter Bank  Account Name"  />
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row mb-3" >
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-website-input" class="form-label">Financial Institution</label>
                        <input type="text" class="form-control" v-model="landlord.institution" placeholder="Enter Bank  Institution Number"  />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Financial Transit</label>
                        <input type="text" class="form-control" v-model="landlord.transfer" placeholder="Enter Bank Transit Number" 
                           />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Financial Account</label>
                        <input type="text" class="form-control" v-model="landlord.account_number" placeholder="Enter Bank Account" 
                         />
                      </div>
                    </div>
                  </div>

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Close</b-button>
                            
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>