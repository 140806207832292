import request from '@/utils/request';



class OwnerApi {

    owner_list = (data) => {
        return request({
            url: '/adm/pm/landlord/landlord_list',
            method: 'post',
            data
        });	
    }
}


let _api = null

const getOwnerApi = () => {
    if (!_api) {
        _api = new OwnerApi();
    }
    return _api;
}

export { getOwnerApi };